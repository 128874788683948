// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1040px,
    xxl: 1140px
);
