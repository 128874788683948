@font-face {
    font-family: 'SVN-Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SVN-Poppins-Regular.ttf");
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: 0 0 1px rgba(255, 255, 255, .25);
}
body * {
    word-break: break-word;
}
.main-body {
    background: #f7f7f7;
}
.payment-body {
    background: #fff;
}
.main-header {
    background: #fff;
    box-shadow: 0px 0px 72px #e7e8eb;
    z-index: 10;
    position: relative;
}
input[type=radio],
input[type=checkbox]{
    width: 20px;
    height: 20px;
}
input[type=text],
select.form-select {
    border-radius: 8px;
}
.payment-rule {
    height: 210px;
    overflow: auto;
    text-align: justify;
    line-height: 2.2;
    font-size: 15px;
    font-family: 'SVN-Poppins-Regular';
}
body * {
    font-family: 'SVN-Poppins-Regular';
    color: #000;
}
.error-message {
    font-size: 13px;
}
h5 {
    font-size: 1rem;
}
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.6rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
@media screen and (min-width: 481px) {
    .school-title {
        font-size: 24px;
    }
    #payment-modal .modal-body {
        padding: 40px 50px;
    }
}
@media screen and (max-width: 480px) {
    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }
    .school-title {
        font-size: 20px;
    }
    #payment-modal .modal-body {
        padding: 30px 0px;
    }
}
.school-info {
    z-index: 100;
}
label.error {
    color: #D70926;
}
input.form-select, textarea {
    background-color : #F4F6FA;
}
select option {
    background-color : #F4F6FA !important;
    color: #000 !important;
}
select.form-select {
    background-color : #F4F6FA !important;
    color: #000 !important;
}
::placeholder {
    color: #A0A4A8 !important;
    opacity: 1 !important;
}
#checkout-button,
#home-button {
    background-color: #0068B8;
}
.policy-url {
    color: #0e97cd;
}
.form-check-input:checked {
    background-color: #0e97cd;
    border-color: #0e97cd;
}
input[name=payment-part].form-check-input:checked {
    background-color: #fff;
    background-image: url("../images/check.png");
    background-size: 13px 13px;
    border: 2px solid #000;
}
input[name=payment-part].form-check-input {
    background-image: none;
    width: 25px;
    height: 25px;
}
input[name=payment-method][type=radio]:checked {
    background-image: url("../images/radio-check.png");
    background-size: 11px 11px;
    border: 2px solid #000;
    background-color: #fff;
}
input[name=payment-method][type=radio] {
    background-image: none;
    width: 25px;
    height: 25px;
}
.payment-rule-alert {
    border: 1px solid #D9D9D9;
    background-color: #F4F6FA;
}
option[value=""][disabled] {
    display: none;
}
input[type=text], select.form-select, input[type=number] {
    background-color : #F4F6FA !important;
}
.result-title {
    font-size: 28px;
}
.result-sub-title {
    font-size: 16px;
}
.result-des-title {
    font-size: 14px;
}
#currency {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}
input[name=payment-part] {
    min-width: 25px;
}
.merchant-logo {
    height: 70px;
}
.no-support-container {
    height: 80vh;
}
.no-support-title {
    font-size: 20px;
}
.payment-modal-close-btn {
    background-color: #0068B8;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    padding: 10px 50px;
}
.payment-modal-desc {
    font-size: 16px;
    color: #25282B;
}
.payment-modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #25282B;
}
#payment-modal .modal-content {
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}
input[name=payment-method]:checked ~ label {
    font-weight: bold;
}
